<template>
  <div class="hotel_details_area">
    <div class="hotel_details_heading">
      <h3>
        <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon19.png`" width="18"
            height="18" alt="booking-flight" /></span>
        {{ $t("booking.flights") }}
      </h3>
    </div>
    <div class="hotel_details_body">
      <div class="d-flex justify-content-between">
        <div class="flight_box_div">
          <ul>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.airline-name") }} :
              </strong>{{ outward.airlineName }}
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.airline-code") }} :
              </strong>{{ outward.airlineCode }}
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.airline-number") }} :
              </strong>{{ outward.airlineNumber }}
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon20.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ outward.departureAddress }}</strong>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon21.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ $t("booking.departure") }} : </strong>{{ outward.depature.dateTime || "" }}
            </li>
            <li class='mergeLine' v-if="outward.depature.notFinal">
              <span>Time not final</span>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon21.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ `${$t("booking.arrivalTo")} ${outward.arrivalAddress} : ` }}</strong>{{
                outward.arrival.dateTime || ""}}
            </li>
            <li class='mergeLine' v-if="outward.arrival.notFinal">
              <span>Time not final</span>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon23.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.including") }} :
              </strong>{{ outward.including }}kg
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.flight-confirmed") }} :
              </strong>{{ outward.confirmed }}
            </li>
          </ul>
        </div>
        <div class="flight_box_div flight">
          <img :src="`${imageDomain}/assets/img/after-booking-icon26.png`" alt="after-booking" title=""
            width="18" height="18" />
        </div>
        <div class="flight_box_div">
          <ul>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.airline-name") }} :
              </strong>{{ inward.airlineName }}
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.airline-code") }} :
              </strong>{{ inward.airlineCode }}
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span><strong>{{ $t("booking.airline-number") }} :
              </strong>{{ inward.airlineNumber }}
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon20.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ inward.departureAddress }}</strong>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon21.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ $t("booking.departure") }} : </strong>{{ (inward.depature.dateTime || "") }}
            </li>
            <li class="mergeLine" v-if="inward.depature.notFinal">
              <span>Time not final</span>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon21.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ `${$t("booking.arrivalTo")} ${inward.arrivalAddress} : ` }}</strong>{{ inward.arrival.dateTime
              || ""}}
            </li>
            <li class="mergeLine" v-if="inward.arrival.notFinal">
              <span>Time not final</span>
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon23.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ $t("booking.including") }} : </strong>{{ inward.including }}kg
            </li>
            <li>
              <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon25.png`"
                  width="18" height="18" alt="after-booking" /></span>
              <strong>{{ $t("booking.flight-confirmed") }} : </strong>{{ inward.confirmed }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  props: {
    flights: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      outward: this.flights.outward,
      inward: this.flights.inward,
    };
  },
  created() { },
};
</script>

<style scoped>
.after_booking_area .hotel_details_area {
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}

.hotel_details_area .hotel_details_heading {
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(39, 136, 210, 1) 0%,
      rgba(1, 98, 172, 1) 100%);
  padding: 15px 20px;
}

.hotel_details_heading h3 {
  margin: 0 0;
  padding: 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.hotel_details_body {
  padding: 35px 20px;
  border: 1px solid #949494;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 25px;
}

.hotel_details_body .flight_box_div {
  padding: 0px 15px;
}

.hotel_details_body .flight_box_div ul {
  padding: 0 0;
  margin: 0 0;
  list-style: none;
}

.hotel_details_body .flight_box_div ul li {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.hotel_details_body .flight_box_div ul li .icon {
  margin-left: 10px;
}

.hotel_details_body .flight_box_div.flight {
  margin: auto;
  text-align: center;
}

.hotel_details_body .flight_box_div.flight img {
  width: 175px;
}

.mergeLine {
  margin-top: -10px;
}

@media (max-width: 1200px) {
  .hotel_details_body .flight_box_div.flight img {
    width: 70px;
  }

  .hotel_details_body .flight_box_div {
    padding: 0px 5px;
  }
}

@media (max-width: 479px) {
  .hotel_details_area .hotel_details_heading h3 {
    font-size: 20px;
  }

  .hotel_details_body .d-flex {
    display: block !important;
  }

  .hotel_details_body {
    padding: 35px 0px;
  }

  .hotel_details_body .flight_box_div.flight {
    margin-bottom: 15px;
  }

  .hotel_details_body .flight_box_div.flight img {
    width: 125px;
  }
}
</style>
